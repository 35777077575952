import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plus_outlined = _resolveComponent("plus-outlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_rest_table = _resolveComponent("rest-table")!
  const _component_form_modal = _resolveComponent("form-modal")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_select_widget = _resolveComponent("select-widget")!
  const _component_a_popconfirm = _resolveComponent("a-popconfirm")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_modal_widget = _resolveComponent("modal-widget")!
  const _directive_perm = _resolveDirective("perm")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_rest_table, { opts: _ctx.tableOpts }, {
      button: _withCtx(() => [
        _withDirectives((_openBlock(), _createBlock(_component_a_button, { onClick: _ctx.openReg }, {
          icon: _withCtx(() => [
            _createVNode(_component_plus_outlined)
          ]),
          default: _withCtx(() => [
            _createTextVNode("开立账户 ")
          ]),
          _: 1
        }, 8, ["onClick"])), [
          [_directive_perm, void 0, "create"]
        ])
      ]),
      _: 1
    }, 8, ["opts"]),
    _createVNode(_component_form_modal, { opts: _ctx.realOpts }, null, 8, ["opts"]),
    _createVNode(_component_modal_widget, { opts: _ctx.regOpts }, {
      content: _withCtx(() => [
        _createVNode(_component_a_form, {
          ref: "formRef",
          "label-col": {span: 6},
          "wrapper-col": {style: {width: '240px'}}
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_form_item, {
              label: "用户名",
              required: true
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input, {
                  value: _ctx.regEntity.alias,
                  "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.regEntity.alias) = $event)),
                  style: {"width":"300px"}
                }, null, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, {
              label: "手机号",
              required: true
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input, {
                  value: _ctx.regEntity.phone,
                  "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.regEntity.phone) = $event)),
                  style: {"width":"300px"}
                }, null, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, {
              label: "登录密码",
              required: true
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input, {
                  value: _ctx.regEntity.pwd,
                  "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.regEntity.pwd) = $event)),
                  style: {"width":"300px"}
                }, null, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, {
              label: "职位",
              required: true
            }, {
              default: _withCtx(() => [
                _createVNode(_component_select_widget, {
                  opts: _ctx.roleOpts,
                  modelValue: _ctx.regEntity.roleName,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.regEntity.roleName) = $event))
                }, null, 8, ["opts", "modelValue"])
              ]),
              _: 1
            }),
            (_ctx.smsOpts.visible)
              ? (_openBlock(), _createBlock(_component_a_form_item, {
                  key: 0,
                  label: "验证码",
                  required: true
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_input, {
                      value: _ctx.regEntity.oidSms,
                      "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.regEntity.oidSms) = $event)),
                      style: {"width":"300px"}
                    }, {
                      suffix: _withCtx(() => [
                        (_ctx.counter === 0)
                          ? (_openBlock(), _createBlock(_component_a_popconfirm, {
                              key: 0,
                              title: `是否发送验证码到 ${ _ctx.smsPhone }?`,
                              "ok-text": "是",
                              "cancel-text": "否",
                              disabled: _ctx.smsOpts.disabled,
                              onConfirm: _ctx.sendSms
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_button, {
                                  type: "text",
                                  disabled: _ctx.smsOpts.disabled
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode("发送验证码")
                                  ]),
                                  _: 1
                                }, 8, ["disabled"])
                              ]),
                              _: 1
                            }, 8, ["title", "disabled", "onConfirm"]))
                          : (_openBlock(), _createBlock(_component_a_button, { key: 1 }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.counter) + " 秒后重新发送", 1)
                              ]),
                              _: 1
                            }))
                      ]),
                      _: 1
                    }, 8, ["value"])
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 512)
      ]),
      _: 1
    }, 8, ["opts"])
  ]))
}